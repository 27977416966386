import React from 'react'

export function useWindowWidthThreshold(threshold: number) {
  const [isBigger, setIsBigger] = React.useState(false)

  const onResize = React.useCallback(() => {
    if (window.innerWidth > threshold) {
      setIsBigger(true)
    } else {
      setIsBigger(false)
    }
  }, [threshold])

  React.useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize, threshold])

  return isBigger
}
