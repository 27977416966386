import React from 'react'
import ReactDOM from 'react-dom'
import {FlatButton, ZIndexes} from '@ambler/andive'
import type {Transition, Variants} from 'framer-motion'
import {motion} from 'framer-motion'
import Link from 'next/link'
import {useRouter} from 'next/router'
import styled from 'styled-components'
import {isIn} from '@ambler/shared'
import {
  AlgoIcon,
  Andiv,
  BurgerIcon,
  CloseIcon,
  DoctorIcon,
  FleetIcon,
  InvoiceIcon,
  MfuIcon,
  Mobile,
  palette,
  TaskIcon,
  Text,
  ProfileIcon,
  DataIcon,
  HospitalIcon,
  TransporterIcon,
  TripIcon,
  LogoutIcon,
  ChartIcon,
  toastInfo,
  Footer,
} from '@ambler/andive-next'
import {gql} from '@apollo/client'
import {useWindowWidthThreshold} from '../../hooks/use-window-width-threshold'
import {useMobile} from '../stack/responsive'
import {useProduct} from '../stack/product'
import {useOnClickOutside} from '../../hooks/use-outside-click'
import {useAuth} from '../stack/auth'
import {getDeviceState, getUpdateDevicePayload, requestNotificationPermissions} from '../../lib/firebase-messaging'
import {useMutation} from '../../hooks/use-mutation'
import type {
  BO_RegisterPushNotificationsDeviceMutation_,
  BO_RegisterPushNotificationsDeviceMutationVariables,
} from './page-layout.generated'

const useIsVeryLargeDesktop = () => useWindowWidthThreshold(1380 + 280)

// **
// * Layout Context
// **

type DrawerState = 'hidden' | 'minimalist' | 'opened' | 'docked' | 'fullpage'
type LayoutContextProps = {
  // * Drawer
  drawerState: DrawerState
  setDrawerState: React.Dispatch<React.SetStateAction<DrawerState>>
}

const LayoutContext = React.createContext<LayoutContextProps>({
  drawerState: 'hidden',
  setDrawerState() {},
})

const LayoutContextProvider: React.FC = ({children}) => {
  const [isReady, setIsReady] = React.useState(false)
  const [prevDrawerState, setDrawerState] = React.useState<DrawerState>('hidden')

  const isDrawerDocked = useIsVeryLargeDesktop()
  const isMobile = useMobile()

  const drawerState: DrawerState = (() => {
    if (isDrawerDocked) {
      return 'docked'
    }

    if (isMobile) {
      if (isIn(['opened', 'fullpage'], prevDrawerState)) {
        return 'fullpage'
      }

      return 'hidden'
    }

    if (isIn(['opened', 'fullpage'], prevDrawerState)) {
      return 'opened'
    }

    return 'minimalist'
  })()

  React.useEffect(() => {
    setIsReady(true)
  }, [])

  React.useEffect(() => {
    setDrawerState(drawerState)
  }, [drawerState])

  if (!isReady) {
    return null
  }

  return <LayoutContext.Provider value={{drawerState, setDrawerState}}>{children}</LayoutContext.Provider>
}

export const usePageLayoutContext = () => React.useContext(LayoutContext)

// **
// * Layout Page Wrapper
// **

// ? The layout wrapper, in which Header Drawer Content Footer or Layout itself
// ? can be nested, and can be placed in any parent container.

type StyledLayoutWrapperProps = {$column?: boolean; $row?: boolean; $minWidth?: number}

const StyledLayoutWrapper = styled.div<StyledLayoutWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  ${props => (props.$row ? 'flex-direction: row' : undefined)};
  ${props => (props.$column ? 'flex-direction: column' : undefined)};

  ${props => (props.$minWidth ? `min-width: ${props.$minWidth}px` : undefined)};

  overflow: hidden;
`

type LayoutProps = StyledLayoutWrapperProps & {
  hasDrawer?: boolean
}

type LayoutPageContextProps = HTMLDivElement | null

const LayoutPageContext = React.createContext<LayoutPageContextProps>(null)

export const PageLayout: React.FC<LayoutProps> = ({children, hasDrawer, ...forwardedProps}) => {
  const [isReady, setIsReady] = React.useState(false)
  const ref = React.useRef()

  React.useEffect(() => {
    setIsReady(true)
  }, [])

  if (hasDrawer) {
    return (
      <LayoutContextProvider>
        <StyledLayoutWrapper ref={ref} {...forwardedProps}>
          <LayoutPageContext.Provider value={ref.current}>
            {isReady && children}
            <div ref={ref} />
          </LayoutPageContext.Provider>
        </StyledLayoutWrapper>
      </LayoutContextProvider>
    )
  }

  return (
    <StyledLayoutWrapper {...forwardedProps}>
      <LayoutPageContext.Provider value={ref.current}>
        {isReady && children}
        <div ref={ref} />
      </LayoutPageContext.Provider>
    </StyledLayoutWrapper>
  )
}

// **
// * Layout Page Header
// **

const StyledHeaderWrapper = styled.div`
  position: relative;
`

export const PageHeader: React.FC = ({children}) => {
  return <StyledHeaderWrapper>{children}</StyledHeaderWrapper>
}

// **
// * Layout Page Content
// **

interface StyledContentWrapperProps {
  $backgroundColor?: string
  $display?: 'block' | 'flex'
  $flexFlow?: 'row' | 'column' | 'row nowrap' | 'column nowrap'
}

const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  position: relative;

  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;

  /* Enables momentum-based scrolling for touch devices */
  -webkit-overflow-scrolling: touch;

  background-color: ${props => props.$backgroundColor ?? 'inherit'};
`

/**
 * This hooks toggles a "visible" className on an element children of the closest PageContent element
 * in the tree that has the "visible-on-scroll" className, everytime the closest PageContent scroll
 * ratio changes between 0 and not 0.
 */
export const useVisibleOnScroll = () => {
  const nodeRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const elm = nodeRef.current
    const observer = new IntersectionObserver(
      ([e]) => {
        const pageContentEl = e.target.closest('.page-content')
        const elementToShowOnScroll = pageContentEl?.querySelector('.visible-on-scroll')
        elementToShowOnScroll?.classList.toggle('visible', e.intersectionRatio < 1)
      },
      {threshold: 1},
    )

    if (elm) {
      observer.observe(elm)
    }

    return () => {
      if (elm) {
        observer.disconnect()
      }
    }
  }, [])

  return [nodeRef] as const
}

export const PageContent = React.forwardRef<
  HTMLDivElement,
  StyledContentWrapperProps & {children: React.ReactNode} & React.HTMLAttributes<HTMLDivElement>
>(function PageContent({children, ...props}, ref) {
  const [nodeRef] = useVisibleOnScroll()

  return (
    <StyledContentWrapper className="page-content" ref={ref} {...props}>
      <div style={{position: 'sticky', top: -1}} className="sticky-trigger" ref={nodeRef} />
      {children}
    </StyledContentWrapper>
  )
})

const PageContentStickyFooterWrapper = styled.div`
  display: none;

  /* An observer will toggle the className "visible" here if the className "visible-on-scroll" is set. */
  &.visible,
  /* This className forces the footer visibility. */
  &.always-visible {
    display: block;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${ZIndexes.ABSOLUTE};
  }
`

export const PageContentStickyFooter: React.FC<{variant: 'always-visible' | 'visible-on-scroll'}> = ({
  variant,
  children,
}) => {
  const {drawerState} = usePageLayoutContext()
  const padding = (() => {
    if (drawerState === 'minimalist' || drawerState === 'opened') {
      return 64
    }

    if (drawerState === 'docked') {
      return 280
    }

    return 0
  })()

  return (
    <PageContentStickyFooterWrapper className={variant}>
      <Footer>
        <Andiv p={`0 16px 0 ${16 + padding}px`} minWidth="100%" justify="center" align="center">
          <div style={{maxWidth: '100%'}}>{children}</div>
        </Andiv>
      </Footer>
    </PageContentStickyFooterWrapper>
  )
}

// **
// * Layout Page Footer
// **

export const PageFooter: React.FC = ({children}) => {
  const layoutPageWrapperDOMNode = React.useContext(LayoutPageContext)

  return layoutPageWrapperDOMNode ? ReactDOM.createPortal(children, layoutPageWrapperDOMNode) : null
}

// **
// * Layout Page Fixed
// **

const PageFixed: React.FC = ({children}) => {
  const domNodeRef = React.useRef<HTMLDivElement>()
  const [isReady, setIsReady] = React.useState(false)

  React.useEffect(() => {
    domNodeRef.current = document.createElement('div')
    // ? we render the content directly inside the body of the page, bypassing the structure so it's over every other content
    document.body.appendChild(domNodeRef.current)
    setIsReady(true)

    return () => document.body.removeChild(domNodeRef.current)
  }, [])

  return isReady ? ReactDOM.createPortal(children, domNodeRef.current) : null
}

// **
// * Layout Page Drawer
// **

type DrawerConfig = {
  items: {
    id: string
    icon: React.ReactNode
    label: string
    href?: string
    onClick?: () => Promise<void> | void
    secondary?: boolean
  }[]
}

const registerPushNotificationsDeviceMutation = gql`
  mutation BO_RegisterPushNotificationsDeviceMutation($data: PushNotificationsUpsertDeviceDataInput!) {
    pushNotificationsUpsertDevice(data: $data)
  }
`

const useDrawerNotificationItem = (): DrawerConfig['items'][number] => {
  const [registerDevice, {loading: deviceLoading}] = useMutation<
    BO_RegisterPushNotificationsDeviceMutation_,
    BO_RegisterPushNotificationsDeviceMutationVariables
  >({
    mutation: registerPushNotificationsDeviceMutation,
  })

  const [canRender, setCanRender] = React.useState(false)

  React.useEffect(() => {
    const getState = async () => {
      setCanRender((await getDeviceState()) === 'INITIAL')
    }
    getState()
  }, [deviceLoading])

  if (!canRender) {
    return null
  }

  return {
    id: 'notifications',
    icon: <TaskIcon color={palette.amblea.white} />,
    label: 'Activer les notifications',
    onClick: async () => {
      const permission_ = await requestNotificationPermissions()

      if (permission_ === 'granted') {
        await registerDevice({
          variables: {
            data: await getUpdateDevicePayload(),
          },
        })
        toastInfo('Les notifications sont activées')
      }
    },
    secondary: true,
  }
}

const useDrawerConfig = (): DrawerConfig => {
  const product = useProduct()
  const isBO = product === 'BO'
  const isA4D = product === 'A4D'
  const notificationItem = useDrawerNotificationItem()

  const {signOut} = useAuth()

  return {
    items: [
      isBO && {
        id: 'dashboard',
        icon: <DataIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Tableau de bord',
        href: '/',
      },
      isBO && {
        id: 'tasks',
        icon: <TaskIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Tâches',
        href: '/tasks',
      },
      isBO && {
        id: 'passengers',
        icon: <TripIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Trajets',
        href: '/passengers',
      },
      isBO && {
        id: 'optimization',
        icon: <AlgoIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Optimisation',
        href: '/optimization',
      },
      isBO && {
        id: 'medical-transporters',
        icon: <TransporterIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Transporteurs',
        href: '/medical-transporters',
      },
      isBO && {
        id: 'medical-facility-units',
        icon: <MfuIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Unités médicales',
        href: '/medical-facility-units',
      },
      isBO && {
        id: 'medical-facilities',
        icon: <HospitalIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Établissements de santé',
        href: '/medical-facilities',
      },
      isBO && {
        id: 'invoices',
        icon: <InvoiceIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Facturation',
        href: '/invoices',
      },
      isBO && {
        id: 'doctors',
        icon: <DoctorIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Médecins',
        href: '/doctors',
      },
      isA4D && {
        id: 'account',
        icon: <ProfileIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Compte',
        href: '/a4d/account',
      },
      isA4D && {
        id: 'dashboard',
        icon: <TaskIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Missions',
        href: '/a4d/dashboard',
      },
      isA4D && {
        id: 'trips',
        icon: <TripIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Trajets',
        href: '/a4d/trips',
      },
      isA4D && {
        id: 'associates',
        icon: <TransporterIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Transporteurs',
        href: '/a4d/associates',
      },
      isA4D && {
        id: 'consortiums',
        icon: <FleetIcon width={32} height={32} color={palette.amblea.white} />,
        label: 'Consortiums',
        href: '/a4d/consortiums',
      },
      notificationItem,
      {
        id: 'export',
        icon: <ChartIcon color={palette.amblea.white} />,
        label: 'Exporter les données',
        href: isBO ? '/export' : '/a4d/trips/export',
        secondary: true,
      },
      {
        id: 'logout',
        icon: <LogoutIcon />,
        label: 'Déconnexion',
        onClick: () => signOut(),
        secondary: true,
      },
    ].filter(Boolean),
  }
}

const StyledDrawerWrapper = styled(motion.div)`
  position: relative;
`

const StyledDrawerContent = styled(motion.div)`
  position: absolute;
  z-index: ${ZIndexes.FIXED};

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${palette.amblea.blue[600]};
`

const StyledDrawerContentTop = styled.div`
  overflow: auto;
`

const StyledDrawerContentBottom = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.5);
`

const StyledLogo = styled.div`
  width: 150px;
  height: 36px;
  background-image: url('/static/img/drawer-full-logo.svg');
  background-repeat: no-repeat;
  background-position-x: 8px;
`

const StyledLogoRoot = styled.div`
  padding: 9px;
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: ${palette.amblea.blue[600]};
`

const StyledItemIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

const StyledItemLabelWrapper = styled(motion.div)<{$secondary?: boolean}>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: ${props => (props.$secondary ? '8px 8px 8px 0' : '8px')};
`

const StyledItemContent = styled(motion.div)<{$secondary?: boolean; $isActive?: boolean}>`
  display: flex;
  flex: 1;
  flex-wrap: no-wrap;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => (props.$isActive ? palette.amblea.blue[500] : palette.amblea.blue[600])};
  overflow: hidden;

  * > svg {
    color: ${props => (props.$isActive || props.$secondary ? palette.amblea.white : palette.amblea.blue[300])};
  }

  * > p {
    color: ${props => (props.$isActive || props.$secondary ? palette.amblea.white : palette.amblea.blue[300])};
  }
`

const StyledCloseButton = styled(FlatButton)`
  align-self: unset;
  &:hover {
    background-color: ${palette.amblea.blue[500]};
  }
  * > svg {
    color: white;
  }
`

const MarginBox = styled.div`
  margin: 8px;
`

export const PageDrawer = () => {
  const {drawerState, setDrawerState} = React.useContext(LayoutContext)
  const isMobile = useMobile()
  const {pathname} = useRouter()
  const config = useDrawerConfig()

  const isActiveItem = (item: DrawerConfig['items'][number]) => {
    return (pathname === '/' && pathname === item.href) || (item.href !== '/' && pathname.startsWith(item.href))
  }

  const transition: Transition = {
    ease: 'easeOut',
    duration: 0.2,
    overflow: {
      // ? we want the overflow transition to be applied:
      // ? - **immediatly** when going from minimalist to full drawer (unset to hidden)
      // ? - **when the drawer finishes closing itself** when going from full to minimalist (hidden to unset)
      // ! we cannot keep it hidden because of the mfu selector which needs to overlap
      duration: 0,
      delay: drawerState === 'minimalist' ? 0 : 0.2,
    },
  }
  const variants: Record<string, Variants> = {
    // PageDrawerRoot
    pageDrawerRoot: {
      minimalist: {
        width: 64,
        transition,
      },
      opened: {
        width: 64,
        transition,
      },
      fullpage: {
        width: 0,
        transition,
      },
      docked: {
        width: 280,
        transition,
      },
      hidden: {
        width: 0,
        transition,
      },
    },
    // PageDrawerContent
    pageDrawerContent: {
      minimalist: {
        overflow: 'hidden',
        width: 64,
        transition,
      },
      opened: {
        overflow: 'unset',
        width: 280,
        transition,
      },
      fullpage: {
        overflow: 'unset',
        width: '100vw',
        x: 0,
        transition,
      },
      docked: {
        overflow: 'unset',
        width: 280,
        transition,
      },
      hidden: {
        overflow: 'unset',
        width: isMobile ? '100vw' : 0,
        x: '-100vw',
        transition,
      },
    },
  }

  const key = (() => {
    if (drawerState === 'docked') {
      return 'xl'
    }
    if (isMobile) {
      return 'small'
    }

    return 'large'
  })()

  return (
    <StyledDrawerWrapper key={key} variants={variants.pageDrawerRoot} initial={false} animate={drawerState}>
      <StyledDrawerContent
        key={key}
        variants={variants.pageDrawerContent}
        initial={false}
        animate={drawerState}
        onHoverStart={isMobile || isIn(['docked', 'hidden'], drawerState) ? undefined : () => setDrawerState('opened')}
        onHoverEnd={
          isMobile || isIn(['docked', 'hidden'], drawerState) ? undefined : () => setDrawerState('minimalist')
        }
      >
        <StyledDrawerContentTop>
          <StyledLogoRoot>
            <StyledLogo />
            {isMobile && (
              <StyledCloseButton type="button" onClick={() => setDrawerState('hidden')} icon={<CloseIcon />} />
            )}
          </StyledLogoRoot>

          {config.items
            .filter(item => !item.secondary)
            .map(item => {
              if (item.href) {
                return (
                  <Andiv column p="8px" key={item.id}>
                    <Link href={item.href}>
                      <a>
                        <StyledItemContent
                          $isActive={isActiveItem(item)}
                          whileHover={{
                            color: palette.amblea.blue[300],
                            backgroundColor: palette.amblea.blue[500],
                          }}
                        >
                          <Andiv align="center">
                            <StyledItemIconWrapper>{item.icon}</StyledItemIconWrapper>
                            <StyledItemLabelWrapper>
                              <Text t="body1">{item.label}</Text>
                            </StyledItemLabelWrapper>
                          </Andiv>
                        </StyledItemContent>
                      </a>
                    </Link>
                  </Andiv>
                )
              }

              return (
                <Andiv column key={item.id} p="8px" onClick={item.onClick}>
                  <StyledItemContent
                    $isActive={isActiveItem(item)}
                    whileHover={{
                      color: palette.amblea.blue[300],
                      backgroundColor: palette.amblea.blue[500],
                    }}
                  >
                    <Andiv align="center">
                      <StyledItemIconWrapper>{item.icon}</StyledItemIconWrapper>
                      <StyledItemLabelWrapper>
                        <Text t="body1">{item.label}</Text>
                      </StyledItemLabelWrapper>
                    </Andiv>
                  </StyledItemContent>
                </Andiv>
              )
            })}
        </StyledDrawerContentTop>
        <StyledDrawerContentBottom>
          {config.items
            .filter(item => item.secondary)
            .map(item => {
              if (item.href) {
                return (
                  <MarginBox key={item.id}>
                    <Link href={item.href}>
                      <a>
                        <StyledItemContent
                          $secondary
                          whileHover={{
                            color: palette.amblea.blue[300],
                            backgroundColor: palette.amblea.blue[500],
                          }}
                        >
                          <Andiv align="center">
                            <StyledItemIconWrapper>{item.icon}</StyledItemIconWrapper>
                            <StyledItemLabelWrapper $secondary>
                              <Text t="body2">{item.label}</Text>
                            </StyledItemLabelWrapper>
                          </Andiv>
                        </StyledItemContent>
                      </a>
                    </Link>
                  </MarginBox>
                )
              }

              return (
                <Andiv column key={item.id} m="8px" onClick={item.onClick}>
                  <StyledItemContent
                    $secondary
                    whileHover={{
                      color: palette.amblea.blue[300],
                      backgroundColor: palette.amblea.blue[500],
                    }}
                  >
                    <Andiv align="center">
                      <StyledItemIconWrapper>{item.icon}</StyledItemIconWrapper>
                      <StyledItemLabelWrapper $secondary>
                        <Text t="body2">{item.label}</Text>
                      </StyledItemLabelWrapper>
                    </Andiv>
                  </StyledItemContent>
                </Andiv>
              )
            })}
        </StyledDrawerContentBottom>
      </StyledDrawerContent>
    </StyledDrawerWrapper>
  )
}

type StyledAsideWrapperProps = {
  $right?: boolean
  $left?: boolean
  $backgroundColor?: string
  $row?: boolean
  $column?: boolean
}

const StyledAsideContent = styled(motion.div)<StyledAsideWrapperProps>`
  position: fixed;
  top: 0;
  left: 100%;

  z-index: ${ZIndexes.FIXED};

  height: 100%;

  ${props => (props.$row ? 'flex-direction: row' : undefined)};
  ${props => (props.$column ? 'flex-direction: column' : undefined)};

  ${props => (props.$backgroundColor ? `background-color: ${props.$backgroundColor}` : undefined)};

  ${props => (props.$left ? `border-right: 1px solid ${palette.amblea.grey[400]}` : undefined)};
  ${props => (props.$right ? `border-left: 1px solid ${palette.amblea.grey[400]}` : undefined)};
`

type AsideProps = StyledAsideWrapperProps & {
  width?: number
  open?: boolean
  onClose?: () => void
}

export const PageAside: React.FC<AsideProps> = ({children, width, open, onClose, ...forwardedProps}) => {
  const isMobile = useMobile()
  const ref = React.useRef()

  const transition: Transition = {ease: 'easeOut', duration: 0.2}
  const variants: Record<string, Variants> = {
    pageAsideContent: {
      hidden: {
        width: isMobile ? '100%' : width,
        x: 0,
        transition,
      },
      opened: {
        width: isMobile ? '100%' : width,
        x: '-100%',
        transition,
      },
    },
  }

  const asideState = open ? 'opened' : 'hidden'

  useOnClickOutside(ref, () => {
    if (!isMobile && open) {
      onClose?.()
    }
  })

  if (isMobile) {
    return (
      <PageFixed>
        <StyledAsideContent
          variants={variants.pageAsideContent}
          initial={false}
          animate={asideState}
          {...forwardedProps}
        >
          {children}
        </StyledAsideContent>
      </PageFixed>
    )
  }

  return (
    <div ref={ref}>
      <StyledAsideContent variants={variants.pageAsideContent} initial={false} animate={asideState} {...forwardedProps}>
        {children}
      </StyledAsideContent>
    </div>
  )
}

export const HeaderBurgerButton = () => {
  const {setDrawerState} = usePageLayoutContext()

  return (
    <Andiv align="baseline">
      <Mobile>
        <FlatButton type="button" onClick={() => setDrawerState('fullpage')} icon={<BurgerIcon />} />
      </Mobile>
    </Andiv>
  )
}
